body {
    background-color: white;
}

.scheme-base-parent-div{
    padding: 120px 72px 50px 72px;
}

.base-paper{
    
    border-radius: 20px;
}

.scheme-name-date-time{
    padding: 32px 24px 32px 24px;
    border-bottom: 2px solid #E6E6E6;
}
.fuel-product-details{
    padding: 32px 24px 32px 24px;
    
}

.tab-label{
    text-transform: capitalize;
    padding: 10px 10px 10px 10px;
}

.slab-eligibility-contents{
    padding: 24px 86px 0px 86px;
    align-items: center;
}

.slab-eligibility-content-data{
   text-align: center;
}

.transaction-start-end-date{
    padding: 32px 24px 0px 24px;
}

.transaction-table-container{
    padding:24px;
}

.redemption-container{
    padding: 61px 34px 50px 34px;

}

.reward-symbol-container{



}

.reward-coin-icon-container{
    align-content: center;
    text-align: center;
}

.reward-points-container{
    padding-top: 24;
}

.reward-points-data{
    width: 100%;
    border-radius: 24px;
    background-color: #FFF6CA;
    padding: 16px 48px 16px 48px;
    display: flex;
    text-align: center;
    justify-content: center;
}

.reward-claim-container{

    padding-top: 24px;
}







/* small screen laptops*/
@media (max-width:1024px){
    .slab-eligibility-contents{
        padding: 20px 32px 20px 32px;
        align-items: center;
    }
}




/* for mobile devices */
@media (max-width:768px){
    .scheme-base-parent-div{
        padding: 100px 0px 0px 0px;
    }

    .base-paper {
        background-color: transparent !important; 
        box-shadow: none !important;
        border: none !important; 

      }

    .custom-mobile-top-padding{
        padding-top: 32px;
    }

    .slab-eligibility-contents{
        padding: 24px 16px 16px 16px;
        align-items: stretch;
    }
    
    .slab-eligibility-content-data{
        text-align: left;
     }

     .transaction-start-end-date{
        padding: 24px 16px 0px 16px;
    }
    
    .transaction-table-container{
        padding: 24px 16px 65px 16px;
    }

    .redemption-container{
        padding: 24px 16px 24px 16px;

    }

    .reward-symbol-container{

    }
    

}

