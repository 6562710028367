
  
/* 
  .ag-header-cell-resize:not(:last-child){
      outline: 2px solid #808080 !important;
      outline-offset: -20px;
    } */
    .ag-theme-material .ag-header-cell-resize:not(:last-of-type) {
        outline: 2px solid #808080 !important;
        outline-offset: -20px;
      }
    
    .ag-header-cell-label{
        justify-content: center;
    }

    .ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
      color: #087dc1 !important;
    }
  
    

  





    /* for mobile devices */
@media (max-width:411px){
  .ag-theme-material .ag-paging-panel > * {
    margin: 0 10px; 
}
}
 
@media (max-width:320px){
    .ag-theme-material .ag-paging-panel > * {
      margin: 0 0px;
  }
  .ag-paging-row-summary-panel{
    margin-right: 2px;
    margin-left: 2px;
  }
  }
