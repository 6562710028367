body {
    background-color: white;
}

.dashboard-base-parent-div{
    padding: 110px 72px 50px 72px;
}


.tab-label{
    text-transform: capitalize;
}

.dashboard-container{
    padding: 24px 0px 0px 0px;
}

.scheme-paper{
    padding: 16px 0px 16px 0px;
    border-radius: 10px;
}

.paper-sections{
    padding: 0px 16px 0px 16px;
}

.paper-hr{
    margin: 0px 0px 0px 0px;
    height:2px;
    border-color: #E6E6E6;
}

@keyframes fadeInOut {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  
  .redeem-text {
    animation: fadeInOut 3s infinite;
    font-size: 16px;
    font-weight: 600;
    color: #0067AC;
    cursor: pointer;
  }


/* for mobile devices */
@media (max-width:768px){
    .dashboard-base-parent-div{
        padding: 132px 16px 0px 16px;
    }
}