.sign-in-container .sign-in-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.sign-in-container .sign-in-card {
  width: 450px;
  max-width: 600px;
  min-width: 320px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0 10px;
  border-radius: 10px;
}

.sign-in-container .sign-in-header {
  color: #287bfa;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}
