.full-width {
  width: 95%;
  height: 100%;
  font-family: "Raleway";
  margin: 10px;
}

.centreLoading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-logo {
  height: 80px;
}
.image-align {
  text-align: center;
  padding: 5px;
}
.address {
  font-size: 8px;
}
.text-align {
  text-align: left;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: medium;
  font-weight: bold;
  border-left: 0.1px groove;
  white-space: inherit;
}
.text-align1 {
  text-align: left;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: medium;
  font-weight: bold;
  word-break: break-all;
}
.border {
  padding: 8px;
  border: 0.1px solid grey;
}
table,
th,
tr {
  border: 0.3px solid grey;
  width: 100%;
  height: 100%;
}
td {
  width: 100%;
  height: 100%;
}

.box {
  margin: 0px 400px;
}

button:focus {
  outline: none;
}

body {
  background: #e6e6e6;
}

@media screen and (max-width: 1100px) {
  .box {
    margin: 0 100px;
  }
}

@media screen and (max-width: 768px) {
  .box {
    margin: 0 100px;
  }
}

@media screen and (max-width: 576px) {
  .box {
    margin: 0px 0px;
    font-size: 9px;
}
@media screen and (max-width:576px) {
  .text-align1 {
    text-align: left;
    padding-top:14px;
    padding-bottom:14px;
    font-size: medium;
    font-weight: bold;
    word-break: break-all;
  }  
}
  @media screen and (max-width:576px) {
    .text-align {
      text-align: left;
      padding-top: 14px;
      padding-bottom: 14px;
      font-size: medium;
      font-weight: bold;
      border-left: 0.1px   groove;
    } 
  }
  @media screen and (max-width: 576px) {
    .text-align {
      text-align: left;
      padding-top: 14px;
      padding-bottom: 14px;
      font-size: medium;
      font-weight: bold;
      border-left: 0.1px groove;
    }
  }

  table {
    font-size: 12px;
  }
}
